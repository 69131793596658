import * as Flow from '@iyk/flow';
import * as React from 'react';

function ModelViewer({
  loadingPlaceholder,
  className,
  width,
  height,
  ...props
}) {
  const [isModelViewerLoaded, setIsModelViewerLoaded] = React.useState(false);
  React.useEffect(() => {
    import('./model-viewer-BeTZBxJd.js').then(() => {
      setIsModelViewerLoaded(true);
    });
  });
  width = width ? isNumberLike(width) ? width + "px" : String(width) : "100%";
  height = height ? isNumberLike(height) ? height + "px" : String(height) : "100%";
  return /* @__PURE__ */ React.createElement("span", { className }, Flow.when(
    isModelViewerLoaded,
    () => /* @__PURE__ */ React.createElement(
      "model-viewer",
      {
        style: {
          width,
          height,
          objectFit: "contain"
        },
        ...props
      }
    ),
    () => loadingPlaceholder ?? null
  ));
}
const isNumberLike = RegExp.prototype.test.bind(/^\d*\.?\d+$/);

export { ModelViewer };

import type { ModelViewerProps } from "@iyk/model-viewer"

import * as Icon from "@iyk/icons"
import * as React from "react"

import { ModelViewer } from "@iyk/model-viewer"
import { cva } from "./cva.ts"

/** Presentational element used to display images and video. */
export const Media = React.forwardRef<HTMLVideoElement | HTMLImageElement, MediaProps>(
  (
    {
      autoRotate = false,
      "camera-controls": cameraControls = false,
      src,
      autoPlay = true,
      loop = true,
      muted = true,
      playsInline = true,
      className,
      ...props
    },
    ref,
  ) => {
    const computedType = React.useMemo(
      () =>
        src
          ? isModelType.test(src)
            ? "model"
            : isVideoType.test(src)
              ? "video"
              : "image"
          : "image",
      [src],
    )

    switch (computedType) {
      case "video":
        return (
          <video
            className={classNameForMedia({ className })}
            src={src}
            autoPlay={autoPlay}
            loop={loop}
            muted={muted}
            playsInline={playsInline}
            disablePictureInPicture
            ref={ref as React.ForwardedRef<HTMLVideoElement>}
            {...(props as React.ImgHTMLAttributes<HTMLVideoElement>)}
          />
        )
      case "model":
        return (
          <ModelViewer
            src={src}
            className={classNameFor3DMedia({ className })}
            loadingPlaceholder={<Icon.LoadingSpinner />}
            rotation-per-second="60deg"
            auto-rotate-delay="0"
            auto-rotate={autoRotate || undefined}
            camera-controls={cameraControls}
            {...(props as ModelViewerProps)}
          />
        )
      default:
        return (
          <img
            className={classNameForMedia({ className })}
            src={src}
            alt=""
            loading="lazy"
            ref={ref as React.ForwardedRef<HTMLImageElement>}
            {...(props as React.ImgHTMLAttributes<HTMLImageElement>)}
          />
        )
    }
  },
)

Media.displayName = "Media"

type MediaProps = Omit<React.ImgHTMLAttributes<HTMLImageElement>, "alt"> &
  React.VideoHTMLAttributes<HTMLVideoElement> &
  ModelViewerProps & {
    autoRotate?: boolean
  }

const classNameForMedia = cva(["object-contain"])
const classNameFor3DMedia = cva(["inline-flex items-center justify-center"])

const isModelType = /\.(glb|gltf)$/i
const isVideoType = /\.(mov|mp4|webm)$/i
